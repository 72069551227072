import { ViewEntityContent } from 'components/ViewEntities/types'

import {
  DayOfWorkOptions,
  workingDaysOfTheWeek,
} from '../../../utilities/dayOfWork'

import {
  OfficeHoursFormData,
  ScaleWithDayWork,
} from 'domains/customer/screens/OfficeHour/types'

import { Scale } from 'services/officeHours/types'
import { PartitionStatus } from 'services/patrimony/types'

export const handleDaySelectedOption = (
  selectedDay: DayOfWorkOptions,
  scales: ScaleWithDayWork[],
) => {
  const valueAlreadyExists = scales.some(
    (scale) => scale.dayOfWork === selectedDay,
  )

  if (valueAlreadyExists)
    return scales.filter((scale) => scale.dayOfWork !== selectedDay)

  if (selectedDay === 'WORKDAYS') {
    return [
      ...scales.filter(
        (scale) => !workingDaysOfTheWeek.includes(scale.dayOfWork),
      ),
      {
        dayOfWork: selectedDay,
        periods: [
          {
            startTime: '',
            endTime: '',
            partitionStatus: PartitionStatus.DISARMED,
          },
        ],
      },
    ]
  }

  return [
    ...scales,
    {
      dayOfWork: selectedDay,
      periods: [
        {
          startTime: '',
          endTime: '',
          partitionStatus: PartitionStatus.DISARMED,
        },
      ],
    },
  ]
}

export const formatEntityCoverage = (
  coverage: OfficeHoursFormData['coverage'],
) => {
  const currentEntity = new Map<string, ViewEntityContent[]>()

  if (coverage?.patrimonies?.length) {
    coverage.patrimonies.map((patrimony) => {
      return currentEntity.set('Patrimônios', [
        ...(currentEntity.get('Patrimônios') || []),
        {
          id: [patrimony.id].join('-'),
          title: patrimony.name,
          subtitle: patrimony.name,
          formKey: 'patrimonies',
        },
      ])
    })
  }

  if (coverage?.accounts?.length) {
    coverage.accounts.map((account) => {
      return currentEntity.set('Contas', [
        ...(currentEntity.get('Contas') || []),
        {
          id: [account.id].join('-'),
          title: account?.precedents?.patrimony?.name || '',
          subtitle: account.accountCode,
          formKey: 'accounts',
        },
      ])
    })
  }

  if (coverage?.partitions?.length) {
    coverage.partitions.map((partition) => {
      return currentEntity.set('Partições', [
        ...(currentEntity.get('Partições') || []),
        {
          id: [partition?.id].join('-'),
          title: [
            partition?.precedents?.patrimony?.name,
            partition?.precedents?.account?.name.split('-')[0].trim(),
            'Central',
          ].join(' > '),
          subtitle: partition?.name || '',
          formKey: 'partitions',
        },
      ])
    })
  }

  return currentEntity
}

export const filterDisarmedPeriodsInterval = (
  scales: ScaleWithDayWork[],
): ScaleWithDayWork[] => {
  const formattedScales = scales.map((scale) => {
    if (scale.periods.length > 1) {
      const filteredArmedPeriods = scale.periods.filter(
        (period) => period.partitionStatus !== PartitionStatus.ARMED,
      )

      return { ...scale, periods: filteredArmedPeriods }
    }

    return scale
  })

  return formattedScales
}

export const groupIntoBusinessDays = (scales: Scale[]): ScaleWithDayWork[] => {
  const WORKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
  const NON_WORKDAYS = ['SUNDAY', 'SATURDAY', 'HOLIDAY']

  const workScales = scales.filter((scale) =>
    WORKDAYS.includes(scale.dayOfWork),
  )

  let allScalesAreTheSame = false
  if (workScales.length === WORKDAYS.length) {
    allScalesAreTheSame = workScales.every((scale) =>
      scale.periods
        ?.sort(
          (a, b) =>
            new Date(`1970-01-01T${a.startTime}:00`).getTime() -
            new Date(`1970-01-01T${b.startTime}:00`).getTime(),
        )
        .every((period, index) => {
          const basePeriod = workScales[0].periods[index]

          return (
            period.startTime === basePeriod.startTime &&
            period.endTime === basePeriod.endTime &&
            period.partitionStatus === basePeriod.partitionStatus
          )
        }),
    )
  }

  let groupedScalesToBusinessDays: ScaleWithDayWork
  if (allScalesAreTheSame) {
    groupedScalesToBusinessDays = {
      dayOfWork: 'WORKDAYS',
      periods: workScales[0].periods,
    }

    return [
      ...scales.filter((scale) => NON_WORKDAYS.includes(scale.dayOfWork)),
      groupedScalesToBusinessDays,
    ]
  }

  return [...scales.filter((scale) => scale)]
}
